/** Pinepointers 7: Tim Hortons Owners **/

import React from "react";
import { preloadImages, scaleFullscreen, showText, hideText, positionText, resetAnimationDelay, loadVideo } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { gsap, Circ } from "gsap";

let globalState, data, preloadData, timerId;

class PinepointersPage7 extends React.Component {

    constructor(props) {
        super(props);

        //update global state and assets data
        globalState = this.props.location.state;
        data = globalState.assets[7];
        preloadData = globalState.assets[8];
        //refs
        this.fullscreenBg = null;

    }

    state = {
        textVisible: false,
    }




    componentDidMount = () => {

        setTimeout(() => {
            
            //Add listener for video 2 end
            this.refs.bgVideo2Ref.addEventListener("ended", this.video2Ended);

            //1. play video
            this.refs.bgVideo1Ref.play();
            this.refs.bgVideo2Ref.play();
            this.refs.bgVideo3Ref.play();

            timerId = setInterval(() => {
                this.checkCurrentTime();
            }, 100);

            //2. animate highlighter
            //gsap.to(this.refs.highlighterWrapperRef, 1, { scale: 1, ease: Circ.easeOut, delay: 0.5 });

            //3. Animate in Badge
            gsap.to(this.refs.timHortonsBadgeRef, 0.75, { x: 0, y: 0, rotate: 0, ease: Circ.easeOut, delay: 1 });

        }, globalState.transitionDuration);


        //position text
        positionText(this.refs.text1Ref, "left", "bottom", globalState.textPosition.leftBottom);


        //show text
        gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0 });

        //add base track
        globalState.baseAudioRef.updateTrack(2, globalState.cdnUrl + data.soundfile2);

        //dim main base track
        //globalState.baseAudioRef.updateVolume(1, 70);

        //Update dimensions
        this.updateDimensions();

        //Add resize listener
        window.addEventListener("resize", this.updateDimensions);

        //Preload assets from next page
        preloadImages([
            globalState.cdnUrl + preloadData.text1, 
            globalState.cdnUrl + preloadData.badge,
            globalState.cdnUrl + preloadData.badgeTop, 
            globalState.cdnUrl + preloadData.background,
            globalState.cdnUrl + preloadData.bgVideoPoster
        ], this.preloadCallback);

        //Hls is added in gatsby-ssr.js
        let videoStream1 = globalState.videoUrl + data.bgVideo1;
        let videoStream2 = globalState.videoUrl + data.bgVideo2;
        let videoStream3 = globalState.videoUrl + data.bgVideo3;
        
        
        loadVideo(this.refs.bgVideo1Ref, videoStream1);
        loadVideo(this.refs.bgVideo2Ref, videoStream2);
        loadVideo(this.refs.bgVideo3Ref, videoStream3);
        

    }

    preloadCallback = () => {
        this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
    }



    componentWillUnmount() {
        //Kill any tweens
        gsap.killTweensOf(this.refs.text1Ref);

        //remove timerId interval
        clearInterval(timerId);

        window.removeEventListener("resize", this.updateDimensions);
    }

    checkCurrentTime = () => {
        if(!this.refs.bgVideo2Ref) {
            clearInterval(timerId);
        } else {
            let t = this.refs.bgVideo2Ref.currentTime
            //console.log(t);
            if(t > 20){
                this.refs.bgVideo2Ref.currentTime = 0;
                this.refs.bgVideo2Ref.play();
            }
        }
    }

    video2Ended = () => {
        console.log("video 2 ended");
        //this.refs.bgVideo2Ref.pauseVideo
    }


    toggleTextDisplay = (event) => {

        //toggle text visibility
        this.setState({ textVisible: !this.state.textVisible });

        //reset the animation delay
        resetAnimationDelay();

        if (this.state.textVisible) {
            //show text
            showText(this.refs.text1Ref, "left", "bottom", globalState.textPosition.leftBottom);


        } else {
            //hide text
            hideText(this.refs.text1Ref, "left");

        }
    }

    isTransitioning = () => {
        this.refs.bgVideo1Ref.pause();
        this.refs.bgVideo2Ref.pause();
        this.refs.bgVideo3Ref.pause();
        //pause animations
    }

    

    updateDimensions = () => {
        console.log('updateDimensions');
        //background native size is 1288 x 724
        scaleFullscreen(this.refs.fullscreenBgRef, window.innerWidth, window.innerHeight, 1288, 724, false);
    }

    render() {

        return (

            <>

                <link rel="prefetch" href="/pinepointers/8" />
                
                {preloadData && (
                    <link rel="preload" as="video" href={globalState.videoUrl + preloadData.bgVideo} />
                )}
                <PrevNext globalState={globalState} ref="prevNextRef"
                    nextLocation="/pinepointers/8" prevLocation="/pinepointers/6"
                    isTransitioning={this.isTransitioning} />
                
                {data && (
                <div className="fullpage-wrapper">
                    <SEO title="Pine Point - Pinepointers" />

                    <div className="text-wrapper">
                        <div className="text text--left-bottom" ref="text1Ref">
                            <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginLeft: "8vw", marginBottom: "15vh" }} onClick={this.toggleTextDisplay} alt="Lyle and Wayne now run three Tim Hortons, nicely situated for success in towns (Hinton, Edson) on the road to Jasper. Their relationship is, well, brotherly – that unique mixture of love and rivalry. They’re proud of Pine Pointers, enough so that they’re going back to visit. But they’ve moved on. What they’ll be exploring there is the past." />
                        </div>
                    </div>

                    <div className="fullscreen-bg pinepointers-bg" ref="fullscreenBgRef">
                        <video ref="bgVideo1Ref" poster={globalState.cdnUrl + data.bgVideoPoster1} autoPlay={false} loop={true} muted={true} playsInline className="video" style={{ width: '627px', height: '439px', top: 0, left: '-1px', position: 'absolute', zIndex: -1 }}>
                            <track kind="captions" srcLang="en" />
                        </video>
                        <video ref="bgVideo2Ref" poster={globalState.cdnUrl + data.bgVideoPoster2} autoPlay={false} loop={true} muted={true} playsInline className="video" style={{  width: '664px', height: '441px', top: 0, left: '625px', position: 'absolute', zIndex: -1 }}>
                            <track kind="captions" srcLang="en" />
                        </video>
                        <video ref="bgVideo3Ref" poster={globalState.cdnUrl + data.bgVideoPoster3} autoPlay={false} loop={true} muted={true} playsInline className="video" style={{ width: '598px', height: '311px', top: '414px', left: '256px', position: 'absolute', zIndex: -1 }}>
                            <track kind="captions" srcLang="en" />
                        </video>
                        <img src={globalState.cdnUrl + data.background} alt="background" className="background" />
                        <img src={globalState.cdnUrl + data.badge} className="badge" alt="The Brothers" style={{ left: '558px', top: '9px' }} />
                        <img src={globalState.cdnUrl + data.badgeTop} className="badge timHortonsBadge" ref="timHortonsBadgeRef" alt="The Tim Hortons Owners" style={{ left: '579px', top: '135px' }} />
                    </div>
                </div>
                )}

            </>
        );
    }


};

export default PinepointersPage7
